import React from "react";
import "./UpcomingMeetings.css";

// logos
import dreamViewImg from "@img/logos/dreamview.png";
import almiralImg from "@img/logos/almiral.jpg";
import aveenoImg from "@img/logos/aveeno.png";
import ceraImg from "@img/logos/cera.png";
import leoImg from "@img/logos/leo.jpg";
import galdermaImg from "@img/logos/galderma.jpg";
import biodermaImg from "@img/logos/bioderma.png";
import relifeImg from "@img/logos/relife.png";
import upcomingBackground from "@img/sponsors_new.jpeg";

const UpcomingMeetings = () => {
  const goldLogos = [dreamViewImg, galdermaImg];
  const silverLogos = [leoImg, ceraImg, relifeImg];
  const bronzeLogos = [almiralImg, biodermaImg, aveenoImg];

  return (
    <main class='content'>
      <section class='virtual virtual--page'>
        <div class='virtual__content'>
          <section class='virtual__top'>
            <h2 class='page__subtitle'>PCDSI 2025</h2>
            <h1 class='page__title'>
              Location: Great Southern Killarney for the 27-29 March 2025
            </h1>
            <div class='top__text'>
              <p>
                27-29 March 2025 - further details to follow
              </p>
            </div>
            {/* <div class='top__btns'>
              <a
                class='btns btns-blue'
                href='https://abbey.eventsair.com/pcdsi-2024/onsite-registration'
                target='_blank'
              >
                Register here
              </a>
            </div>
            <div class='top__hint'>
              <p>Don't miss out. Register today via the Abbey events website</p>
            </div>
            <a
              className='download-programme'
              href='https://abbey.eventsair.com/AbbeyEventApp/pcdsi-2024/programme/Agenda'
              target="_blank"
            >
              Conference Information
            </a> */}
          </section>
          {/* <section class='virtual__hiw'>
            <div class='section__title'>How it works</div>
            <div class='hiw__list'>
              <div class='list__item'>
                <div class='card'>
                  <div class='hiw__title'>
                    <span>Before the conference</span>
                  </div>
                  <div class='hiw__text'>
                    In advance of the conference commencing, you will receive
                    access to the Virtual Conference Platform to customise your
                    profile and start building your schedule. All sessions,
                    activities and networking will take place in the online
                    platform. All you need to join is a computer, internet
                    access and an up-to-date web browser. We recommend Google
                    Chrome for the best user experience.
                  </div>
                </div>
              </div>
              <div class='list__item'>
                <div class='card'>
                  <div class='hiw__title'>
                    <span>Enjoy live sessions</span>
                  </div>
                  <div class='hiw__text'>
                    Attendees will be able to join live presentations and these
                    live streamed sessions give you the opportunity to engage
                    with presenters and delegates via the live Q&A function
                    during each presentation.
                  </div>
                </div>
              </div>
              <div class='list__item'>
                <div class='card'>
                  <div class='hiw__title'>
                    <span>Connect with your community</span>
                  </div>
                  <div class='hiw__text'>
                    Make contact with old and new colleagues via the Meeting
                    hub. You will be able to search for other delegates
                    attending PCDSI 2022 and use the chat function, and schedule
                    video meetings within the platform.
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <h1 className='gold-sponsors'>Gold Sponsors</h1>
          <div className='sponsors-container'>
            {goldLogos.map((logo) => (
              <img src={logo} className='sponsors' alt='gold sponsor image' />
            ))}
          </div>
          <h1 className='gold-sponsors'>Silver Sponsors</h1>
          <div className='sponsors-container'>
            {silverLogos.map((logo) => (
              <img src={logo} className='sponsors' alt='silver sponsor image' />
            ))}
          </div>
          <h1 className='gold-sponsors'>Bronze Sponsors</h1>
          <div className='sponsors-container'>
            {bronzeLogos.map((logo) => (
              <img src={logo} className='sponsors' alt='bronze sponsor image' />
            ))}
          </div> */}
          <div className='virtual__bottom'>
            <img src={upcomingBackground} alt='upcoming image' />
          </div>
        </div>
      </section>
    </main>
  );
};

export default UpcomingMeetings;
